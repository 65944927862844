import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard {
  constructor(
    private _api: ApiService,
    private _router: Router
  ) {
    console.log('GuestGuard constructor');
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._api.get('auth').pipe(
      map((response: any) => {
        console.log('GuestGuard canLoad response', response);

        let name: any = response.user.name;

        if (!!name) {
          this._router.navigate(['admin/websites']);
        }

        this._api.nameEmitter.next(name);
        this._api.nameEmitter.complete();

        return !!name;
      }),
      catchError((error: any) => {
        console.log('GuestGuard catchError error', error);
        this._api.nameEmitter.next(null);
        this._api.nameEmitter.complete();


        return of(true);
      })
    );
  }
}
