
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ControlCustom } from '../modules/admin/pages/dashboard-page/form-controls/control-custom.class';




export const API_URI = environment.base_uri;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public nameEmitter = new ReplaySubject();
  public nameObservable = this.nameEmitter.asObservable();
  private _classTypes = new BehaviorSubject<any[]>([]);
  classTypes$ = this._classTypes.asObservable();

  public organizationEmitter = new ReplaySubject();
  public organizationObservable = this.organizationEmitter.asObservable();

  public websiteEmitter = new BehaviorSubject<string>('');
  public websiteObservable = this.websiteEmitter.asObservable();

  private _structure = new BehaviorSubject<ControlCustom[]>([]);
  _structure$ = this._structure.asObservable();
  constructor(private _router: Router, private _http: HttpClient) {}


  setStructure(data: ControlCustom[]) {
    this._structure.next(data);
  }
  getListStructure() {
    return this._structure.value;
  }
  setOrganization(uuid: string) {
    this.organizationEmitter.next(uuid);
  }

  setWebsite(uuid: string) {
    this.websiteEmitter.next(uuid);
  }


  /** POST REQUEST */
  public post(url: string, params: any): Observable<any> {
    return this._http.post(`${API_URI}/${url}`, params, this._getOptions());
  }

    /** PUT REQUEST */
    public put(url: string, params: any): Observable<any> {
      return this._http.put(`${API_URI}/${url}`, params, this._getOptions());
    }

    /** GET REQUEST */
    public get(url: string): Observable<any> {
      return this._http.get(`${API_URI}/${url}`, this._getOptions());
    }

    public getParams(url: string, params: any): Observable<any> {
      let options = {
        params: new HttpParams()
      };
      for (let param in params) {
        options.params = options.params.set(param, params[param]);
      }
      return this._http.get(`${API_URI}/${url}`, {...this._getOptions(), ...options});
    }


/** DELETE REQUEST */
    public delete(url: string, uuid: string): Observable<any> {
      return this._http.delete(`${API_URI}/${url}/${uuid}`, this._getOptions());
    }

  public logout(): void {
    this.post('logout', {}).subscribe({
      next: (response: any) => {
        console.log('ApiService logout response', response);
        localStorage.removeItem('token');
        this._router.navigate(['login']).then(() => {
          window.location.href = window.location.href;
        });
      },
      error: (error: any) => {
        console.log('ApiService logout error', error);
        localStorage.removeItem('token');
      },
    });
  }

  public async getName(): Promise<any> {
    try {
      const name = await lastValueFrom(this.nameEmitter);
      if (!name) this.logout();
      return name;
    } catch (error) {
      this.logout();
      return null;
    }
  }

    /** HTTP HEADERS */
    private _getOptions() {
      let token: string | null = localStorage.getItem('token');
      let httpOptions = {
        headers: new HttpHeaders({
          observe: 'response',
          responseType: 'json',
          Authorization: 'Bearer ' + token,
        }),
      };
      return httpOptions;
    }

    gettypeClass() {
      this.get(`classes?paginate=0`).subscribe(
        ({ data }: any) => {
          this._classTypes.next(data);
        },
        (error) => {
          console.error('Error:', error.message || 'Unknown error');
          console.log('Error details:', error);
        }
      );
    }
    getListaTypeClass() {
      return this._classTypes.value;
    }
}
