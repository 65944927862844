import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService,
    private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.add();

/*     const token = localStorage.getItem('token');
    if (!token) {
        this.spinnerService.remove();
        this.router.navigate(['/login']);
        return throwError('No token found');
    } */

   /*  request = request.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`
        }
    }); */

    return next.handle(request)
      .pipe(
        tap(
          event=>{
           /*  console.log(event) */
          },
          error=>{
            console.log(error)
          }
        ),
        finalize(
          ()=>{
            this.spinnerService.remove();
          }
        )
      );
  }
}
