import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  count = 0;

  constructor(private spinner: NgxSpinnerService) { }
  add(){
    this.count++;
    this.spinner.show();
  }
  remove(){
    if(this.count > 0) this.count--;
    if(this.count == 0) this.spinner.hide();
  }
}
