import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad{
  constructor(
    private _api: ApiService,
    private _router: Router,

  ) {
    console.log('AuthGuard constructor');
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._api.get('auth').pipe(
      map((response: any) => {
       /*  console.log('AuthGuard canLoad response', response);
        console.log('AuthGuard canLoad response', response.data); */

        let name: any = response.data.name;

        if (!name) {
          this._router.navigate(['login']);
        }

        this._api.nameEmitter.next(name);
        this._api.nameEmitter.complete();

        return !!name;
      }),
      catchError((error: any) => {
        console.log('AuthGuard catchError error', error);
        this._router.navigate(['login']);
        this._api.nameEmitter.next(null);
        this._api.nameEmitter.complete();
        return of(true);
      })
    );
  }
}
